import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { forkJoin, mergeMap } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { CreditsService } from 'src/app/services/credits.service';
import {
  Product,
  ProductPeriodUnit,
  ProductPlan,
  ProductPlanCodes,
} from 'src/app/types/product-plan.type';
import { Decimal } from 'decimal.js';

@Component({
  selector: 'itfg-product-plan-slider',
  templateUrl: './product-plan-slider.component.html',
  styleUrls: ['./product-plan-slider.component.scss'],
})
export class ProductPlanSliderComponent {
  @ViewChild('principalInputRef') principalInputRef!: ElementRef;
  @ViewChild('periodInputRef') periodInputRef!: ElementRef;

  isPrincipalEditing = false;
  isPeriodEditing = false;
  productPlanList: ProductPlan[] = [];
  _product: Product = {} as Product;

  @Input()
  set viewConfig(settings: {
    showSummaryFooter: boolean;
    showPeriodSlider: boolean;
  }) {
    this.viewSettings = settings;
  }

  viewSettings = {
    showSummaryFooter: true,
    showPeriodSlider: true,
  };
  public productPlanSummary = {
    installmentAmount: 0,
    totalReturnAmount: 0,
    totalCostIncrease: 0,
  };
  get product() {
    return this._product;
  }
  set product(value: Product) {
    this._product = value;
    this.productChanged(value);
  }
  _selectedPrincipalIndex = 0;
  _selectedPeriodIndex = 0;
  get selectedPrincipalIndex() {
    return this._selectedPrincipalIndex;
  }
  set selectedPrincipalIndex(value: number) {
    this._selectedPrincipalIndex = value;
    this.setTargetPlan();
  }
  get selectedPeriodIndex() {
    return this._selectedPeriodIndex;
  }
  set selectedPeriodIndex(value: number) {
    this._selectedPeriodIndex = value;
    this.setTargetPlan();
  }
  allowedPrincipalList: number[] = [];
  maxPeriod: number = 0;
  periodsList: number[] = [];
  targetPlan: ProductPlan;
  productList: Product[];
  allowedPeriodsMap: Map<number, Map<number, number[]>> = new Map();
  principalControl = 0;

  @Output() targetPlanChanged: EventEmitter<ProductPlan> =
    new EventEmitter<ProductPlan>();

  constructor(private credits: CreditsService, private auth: AuthService) {
    this.fetchProducts();
  }

  startPrincipalEdit() {
    this.isPrincipalEditing = true;
    setTimeout(() => {
      this.principalInputRef.nativeElement.select();
    });
  }

  startPeriodEdit() {
    this.isPeriodEditing = true;
    setTimeout(() => {
      this.periodInputRef.nativeElement.select();
    });
  }

  findClosestValue(value: number, array: number[]): number {
    return array.reduce((prev, curr) => {
      return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
    });
  }

  finishPrincipalEdit(event: Event): void {
    const inputValue = +(event.target as HTMLInputElement).value;

    if (!isNaN(inputValue)) {
      const closestValue = this.findClosestValue(
        inputValue,
        this.allowedPrincipalList
      );
      const newIndex = this.allowedPrincipalList.indexOf(closestValue);

      if (newIndex !== -1) {
        this.selectedPrincipalIndex = newIndex;
        this.onPrincipalInputChange();
        this.setTargetPlan();
      }
    }

    this.isPrincipalEditing = false;
  }

  finishPeriodEdit(event: Event) {
    const inputValue = +(event.target as HTMLInputElement).value;

    if (!isNaN(inputValue)) {
      const closestValue = this.findClosestValue(inputValue, this.periodsList);
      const newIndex = this.periodsList.indexOf(closestValue);
      if (newIndex !== -1) {
        this.selectedPeriodIndex = newIndex;
        this.onPeriodInputChange();
      }
    }

    this.isPeriodEditing = false;
  }

  principalStepUp() {
    this.selectedPrincipalIndex = Math.min(
      this.allowedPrincipalList.length - 1,
      this.selectedPrincipalIndex + 1
    );
  }

  principalStepDown() {
    this.selectedPrincipalIndex = Math.max(0, this.selectedPrincipalIndex - 1);
  }

  periodStepUp() {
    this.selectedPeriodIndex = Math.min(
      this.periodsList.length - 1,
      this.selectedPeriodIndex + 1
    );
  }

  periodStepDown() {
    this.selectedPeriodIndex = Math.max(0, this.selectedPeriodIndex - 1);
  }

  onPeriodInputChange() {
    const currentAmount =
      this.allowedPrincipalList[this.selectedPrincipalIndex];
    const nextPeriodValue = this.periodsList[this.selectedPeriodIndex];
    const allowedPeriods: number[] = this.allowedPeriodsMap
      .get(this.product.id)
      .get(currentAmount);
    if (!allowedPeriods.includes(nextPeriodValue)) {
      this.selectedPeriodIndex = allowedPeriods.length - 1;
    }
  }

  fetchProducts() {
    const brandCode = this.auth.currentUser?.brand.code;
    forkJoin([
      this.credits.fetchProducts$(brandCode),
      this.credits.fetchProductPlans$(brandCode),
    ]).subscribe(([products, productPlans]) => {
      this.productList = products.filter((product) => {
        const hasProductPlans = productPlans
          .map((plan) => plan.product.id)
          .includes(product.id);
        return hasProductPlans;
      });
      const installmentsProduct = this.productList[0];
      this.productPlanList = productPlans;
      this.createPeriodsMap(this.productPlanList);
      this.product = installmentsProduct;
    });
  }

  productChanged(product: Product) {
    const currentProductPlans = this.productPlanList.filter(
      (p) => p.product.id === product.id
    );
    const currentPrincipal =
      this.allowedPrincipalList[this.selectedPrincipalIndex];
    this.allowedPrincipalList = [
      ...new Set(currentProductPlans.map((p) => p.principal)),
    ];
    this.selectedPrincipalIndex = Math.max(
      this.allowedPrincipalList.indexOf(currentPrincipal),
      0
    );
    this.selectedPeriodIndex = 0;
    let periodMapper = (productPlan: ProductPlan) =>
      productPlan.installmentsNumber;
    if (this.product.periodUnit === ProductPeriodUnit.DAY) {
      this.maxPeriod = 1;
      periodMapper = (productPlan: ProductPlan) => productPlan.installmentDays;
    } else {
      this.maxPeriod = 23;
    }
    this.periodsList = [...new Set(currentProductPlans.map(periodMapper))];
    this.setTargetPlan();
  }

  createPeriodsMap(productPlans: ProductPlan[]) {
    for (let plan of productPlans) {
      if (!this.allowedPeriodsMap.has(plan.product.id)) {
        this.allowedPeriodsMap.set(plan.product.id, new Map());
      }
      const current = this.allowedPeriodsMap.get(plan.product.id);
      if (!current.has(plan.principal)) {
        current.set(plan.principal, []);
      }
      const installmentsLoanSelected =
        plan.product.periodUnit === ProductPeriodUnit.MONTH;
      const period = installmentsLoanSelected
        ? plan.installmentsNumber
        : plan.installmentDays;
      if (!current.get(plan.principal).includes(period)) {
        current.get(plan.principal).push(period);
      }
    }
  }

  onPrincipalInputChange() {
    this.onPeriodInputChange();
  }

  setTargetPlan(): void | boolean {
    if (this.productPlanList.length === 0) {
      return false;
    }
    this.targetPlan = this.findTargetPlan();
    this.targetPlanChanged.emit(this.targetPlan);
    this.productPlanSummary = this.calculateProductPlanSummary(this.targetPlan);
  }

  calculateProductPlanSummary(productPlan: ProductPlan) {
    if (!productPlan) {
      return this.productPlanSummary;
    }
    const {
      principal,
      installmentsNumber,
      installmentAmount,
      installmentForfeit,
      installmentUtilizationFee,
    } = productPlan;
    const totalInstallmentAmount: Decimal = new Decimal(installmentAmount)
      .plus(new Decimal(installmentForfeit))
      .plus(new Decimal(installmentUtilizationFee));
    let totalReturnAmount: Decimal = totalInstallmentAmount.mul(
      new Decimal(installmentsNumber)
    );
    let difference = totalReturnAmount.minus(principal).abs();
    let isWithinThreshold: boolean = difference.lessThanOrEqualTo(
      new Decimal(0.05)
    );
    if (isWithinThreshold) {
      totalReturnAmount = new Decimal(principal);
    }
    const totalCostIncrease: Decimal = totalReturnAmount.minus(
      new Decimal(principal)
    );
    const summary = {
      installmentAmount: totalInstallmentAmount.toNumber(),
      totalReturnAmount: totalReturnAmount.toNumber(),
      totalCostIncrease: totalCostIncrease.toNumber(),
    };
    return summary;
  }

  findTargetPlan() {
    const currentProductPlans = this.productPlanList.filter(
      (p) => p.product.id === this.product.id
    );
    return currentProductPlans.find((productPlan) => {
      const principalValue =
        this.allowedPrincipalList[this.selectedPrincipalIndex];
      const periodValue = this.periodsList[this.selectedPeriodIndex];
      const principalMatch = productPlan.principal === principalValue;
      let periodMatch = productPlan.installmentsNumber === periodValue;
      if (this.product.periodUnit === ProductPeriodUnit.DAY) {
        periodMatch = productPlan.installmentDays === periodValue;
      }
      return principalMatch && periodMatch;
    });
  }
}
