import { CreditInstallment } from "./credit.type";

export enum InstallmentStatus {
    OVERDUE = 'OVERDUE',
    PAID = 'PAID',
    INCOMING = 'INCOMING'
}

export function calculateInstallmentStatus(installment: CreditInstallment): InstallmentStatus {
    if (installment.paymentDate) {
        return InstallmentStatus.PAID;
    }

    const today = new Date().setHours(0, 0, 0, 0);
    const maturityDate = new Date(installment.maturityDate).setHours(0, 0, 0, 0);

    if (maturityDate < today) {
        return InstallmentStatus.OVERDUE;
    }

    return InstallmentStatus.INCOMING;
}

export function getCurrentInstallment(installments: CreditInstallment[]): CreditInstallment | null {
    if (!installments.length) return null;
  
    const sortedInstallments = [...installments].sort(
        (a, b) => new Date(a.maturityDate).getTime() - new Date(b.maturityDate).getTime()
    );
  
    const today = new Date();
  
    return sortedInstallments.find(
        installment => 
            new Date(installment.maturityDate) > today && 
            !installment.paymentDate
    ) || null;
  }
  