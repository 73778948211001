import { BrandCodes } from './application.type';

export class UserLoginDto {
  identity: string = '';
  password: string = '';
  ip: string = '';
  device: string = window.navigator.userAgent.toString();

  constructor(partial: Partial<UserLoginDto>) {
    Object.assign(this, partial);
  }
}

export class TrustedDevice {
  id: number;
  uuid: string;
  createTime: string;
  lastUsed: string;
  expire: string;
  trusted: boolean;
  userAgent: {
    agent: string;
  };
}

export class UserDetails {
  id: number;
  code: string;
  employerId: string;
  email: string;
  emailConfirmed: true;
  phone: string;
  name: string;
  iban: string;
  brand?: {
    name: string;
    code: BrandCodes;
  };
  roles?: string[];
  trustedDevices?: TrustedDevice[];

  get isDental() {
    return this.brand.code === BrandCodes.MF;
  }

  constructor(partial: Partial<UserDetails>) {
    Object.assign(this, partial);
    if (this.code.startsWith('DENT')) {
      this.brand = {
        name: 'DENTAL',
        code: BrandCodes.MF,
      };
    } else {
      this.brand = {
        name: 'SMILE CREDIT',
        code: BrandCodes.SC,
      };
    }
  }
}

export enum UserDocumentType {
  ID_FRONT = 'ID_FRONT',
  ID_BACK = 'ID_BACK',
  SELFIE = 'SELFIE',
}
