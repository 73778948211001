import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { StorageService } from './storage.service';
import { APP_CONFIG } from '../config/app.config';
import { Router } from '@angular/router';

const AUTH_CONFIG = APP_CONFIG.AUTH;

// export const bearerAuthApiURLs = [
//   APP_CONFIG.AUTH.API.CLIENT_COMMUNICATION,
// ]

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private storage: StorageService,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const authToken = this.storage.getItem(AUTH_CONFIG.SESSION_TOKEN_NAME) || null;
    let authenticatedRequest = request;
    if (authToken) {
      authenticatedRequest = request.clone({
        headers: request.headers.set(
          AUTH_CONFIG.SESSION_TOKEN_NAME,
          authToken
        ).set('Authorization', 'Bearer ' + authToken)
      });
    }

    return next.handle(authenticatedRequest).pipe(
      tap({
        error: (error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.storage.removeItem(AUTH_CONFIG.SESSION_TOKEN_NAME);
            this.router.navigate(['/auth']);
          }
        }
      })
    );
  }
}
